<template>
  <static-fullscreen-card @sendHeight="setContentHeight">
    <template v-slot:title>
      ({{ data.id }}) {{ m.title + ` №${data.code_doc}` }} [{{ statusCurrent.text || "" }}]
      <a-btn-print @click="showPrint = true" />
    </template>
    <template v-slot:actions>
      <v-tooltip bottom v-if="hintStatus">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">fas fa-info-circle</v-icon>
        </template>
        <span>{{ hintStatus }}</span>
      </v-tooltip>

      <a-btn-status
        v-if="getAccess('documents.goodsIncome.status', { users: [data.createdby_id] })"
        :items="statusItems"
        @click="changeStatus($event)"
        :disabled="canStatus"
      />
      <a-btn-edit v-if="data.status == 1 && getAccess('documents.goodsIncome.edit', { users: [data.createdby_id] })" @click="showEditDialog = true" />
      <a-btn-delete
        v-if="
          id &&
          data.status == 1 &&
          data.data_table &&
          !data.data_table.length &&
          getAccess('documents.goodsIncome.delete', {
            users: [data.createdby_id],
          })
        "
        @click="removeDialogShow = true"
      />
    </template>
    <portal to="v-goup" v-if="idShow">
      <v-btn class="mr-3" fab small @click="$emit('close', data)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </portal>
    <v-row>
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-form-view :value="data" :model="modelLeft" :config="{ dense: true }" :style1="{ height: blockHeight + 'px' }" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-form-view :value="data" :model="modelRight" :config="{ dense: true }" :style1="{ height: blockHeight + 'px' }" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="12" class="px-2">
        <material-card color="third" class="block_detail">
          <template v-slot:heading>
            <v-tabs v-model="tab" background-color="transparent" slider-color="white">
              <v-tab v-for="(tab, i) in tabs" v-if="tab.show" :key="i" class="mr-3">
                {{ tab.title }}
              </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px" touchless>
            <v-tab-item>
              <v-card-text :style="`height: ${detailHeight}px; overflow: auto`" class="pa-0">
                <a-table-f-data2
                  ref="tableDetail"
                  :dataTable="data.data_table"
                  :model="modelDetail"
                  :searchable="false"
                  :defaults="{
                    sort: { key: 'id', order: 'DESC' },
                    paramName: 'documentGoodsIncomeDetail',
                  }"
                  @click="onClickRow($event)"
                >
                  <template v-slot:item.actions="{ row }">
                    <div class="d-flex" v-if="data.status == 1 && getAccess('documents.goodsIncome.edit', { users: [data.createdby_id] })">
                      <v-btn x-small fab class="ma-0" color="red" title="Удалить" @click.stop="delRow(row.id)">
                        <v-icon small>fas fa-times</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <template v-slot:top>
                    <v-fab-transition
                      v-if="
                        data.status == 1 &&
                        getAccess('documents.goodsIncome.edit', {
                          users: [data.createdby_id],
                        })
                      "
                    >
                      <v-btn fab dark color="green" absolute bottom small style="bottom: 15px" left class="v-btn--example" @click="addRecord">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-fab-transition>
                  </template>
                </a-table-f-data2>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <comment-view :id="id || 0" :targetName="'goodsIncome'" :needUpdate="needUpdateComments" />
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
    <div v-if="loading" class="loading-block">
      <div class="loader"></div>
    </div>
    <edit-dialog
      v-if="showEditDialog"
      v-model="showEditDialog"
      :id="id"
      :api="url"
      :m="m"
      :readonly="data.status !== 1"
      :hasData="data.data_table && data.data_table.length > 0"
    />
    <add-Goods v-if="showDialogAddGoods" v-model="showDialogAddGoods" :storage="data.store" :order_id="parseInt(data.parent_id)" @save="addRows($event)" />
    <edit-Goods
      v-if="showDialogRowEdit"
      v-model="showDialogRowEdit"
      :id="idEdit"
      :type="data.operation_type"
      :dataTable="data.data_table"
      :RO="fieldsRO"
      @save="editRows($event)"
    />
    <s-Editor-editor
      v-if="getAccess('suData')"
      ref="suEdit"
      v-model="suEditShow"
      :config="{ table: 'accounting_doc_goods_income_table', filter: { parent_id: data.id } }"
      @show="suEditShow = true"
      :activate="(data.id || 0) > 0"
    />
    <PrintForm v-if="showPrint" v-model="showPrint" :data="data" :models="{ modelLeft, modelRight, modelDetail }" />
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" :title="removeTitle" />
    <confirm-dialog ref="confirmDialog" />
    <change-value-dialog ref="changeValueDialog" />
    <a-loader v-if="loading" />
    <popup-menu v-model="popumMenuShow" :items="popupMenuList" :position="popupMenuPosition" @click="onClickMenu($event)" />
  </static-fullscreen-card>
</template>

<script>
//import { statusTask } from "@/config/modules/libs";

import { autoHeightBlock, getAccess, popupMenu, removeEl, genModel, comments } from "@/components/mixings";
import libsCash from "../libsCash";
export default {
  mixins: [autoHeightBlock, getAccess, popupMenu, removeEl, genModel, libsCash, comments],
  components: {
    addGoods: () => import("./../dialogs/incomeAddGoodsDialog"),
    editGoods: () => import("./../dialogs/incomeEditGoodsDialog"),
    editDialog: () => import("./../dialogs/goodsIncomeCreateDialog"),
    PrintForm: () => import("@/views/print/goodsIncome"),
  },
  props: {
    idShow: Number,
  },
  data() {
    return {
      id: 0,
      idEdit: 0,
      fieldsRO: [],

      accDocName: "AccountingDocOrderGoodModel",
      showEditDialog: false,
      showDialogRowEdit: false,
      suEditShow: false,
      removeDialogShow: false,
      showPrint: false,
      removeTitle: "Вы действительно хотите удалить документ?",
      loading: false,
      loaded: false,
      title: "",
      data: {},
      modelDetail: this.$store.getters["config/get"].models.documentGoodsIncome.listDetail,
      m: this.$store.getters["config/get"].models.documentGoodsIncome,
      statuses: this.$store.getters["config/get"].models.documentGoodsIncome.statuses,
      url: "/accounting/doc/goods_income",
      api: "/accounting/doc/goods_income",
      apiStatus: "/accounting/doc/goods_income",
      tab: 0,
      tabs: [
        { title: "Детали", show: true },
        { title: "Комментарии", show: true },
      ],
      showDialogAddGoods: false,
      blockHeight: 256,
      statusCur: null,
      hintStatus: null,
    };
  },
  created() {
    this.loading = true;

    this.$root.title = "Приемка товаров";
    this.id = this.idShow || Number(this.$route.params.id);
    this.fitchData();
    this.loaded = true;
  },
  mounted() {},

  computed: {
    canStatus() {
      this.hintStatus = null;
      if (this.data.data_table && this.data.data_table.length == 0) {
        this.hintStatus = "В документе ничего нет";
        return true;
      }
      if (
        this.data.status == 1 &&
        this.data.data_table &&
        this.data.data_table.filter((el) => {
          if (el.amount == 0 || !el.amount) return true;
        }).length !== 0
      ) {
        this.hintStatus = "Не везде заполнено значение количества";

        return true;
      }

      return (this.data.data_table && this.data.data_table.length == 0) || this.loading;
    },
    canEdit() {
      return this.loading || this.data.data_table.length !== 0 || false;
    },
    model: {
      get() {
        let model = this.getModelList(this.m, "form", true);
        this.modelReady = true;
        return model;
      },
    },
    modelLeft() {
      return this.calcModel("viewForm1");
    },
    modelRight() {
      return this.calcModel("viewForm2");
    },
    statusItems() {
      if (!this.data.status) return [];
      let items = [];
      let res = [];
      const statusCur = this.statusCurrent;
      if (statusCur && statusCur.next) {
        let arr = statusCur.next;
        res = this.statuses.filter((s) => {
          return arr.includes(s.value);
        });
      }
      items = JSON.parse(JSON.stringify(res));
      return items;
    },
    statusCurrent() {
      let statusCur = this.statuses.find((el) => el.value == this.data.status);
      if (!statusCur) {
        statusCur = {};
      }
      return JSON.parse(JSON.stringify(statusCur));
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
      }
    },
  },
  methods: {
    async fitchData() {
      this.loading = true;
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
      this.afterFetchData();
      this.loading = false;
    },

    onClickRow(e) {
      if (e.field?.isMenu) {
        this.showPopupMenu(e.field.name, e.row, e.event);
      } else if (e.field.name == "actions") {
      } else {
        this.idEdit = e.row.id;
        this.fieldsRO =
          this.data.operation_type == 1 //по чек-листу
            ? ["name", "amount", "measure", "price_plan"]
            : [];
        this.showDialogRowEdit = true;
      }
    },
    addRecord() {
      this.showDialogAddGoods = true;
    },
    async addRows(r) {
      const api = this.url;
      let order_new = null;

      const data_table = [...this.data.data_table, ...r];
      console.log("addRow", { r, data_table });
      for (let i = 0; i < data_table.length; i++) {
        let order_cur = data_table[i].id ? data_table[i]._jm_accounting_doc_order_goods_table_1_parent_id : data_table[i].order_goods_id;
        if (!order_new) order_new = order_cur;
        if (!order_new) {
          this.$root.$emit("show-info", {
            type: "error",
            text: `Ошибка при определении документа заказа товара для "${data_table[i].name}"`,
          });
          return;
        }
        if (order_new !== order_cur) {
          this.$root.$emit("show-info", {
            type: "error",
            text: `Принимаемые товары должны быть из одного заказа товаров "${data_table[i].name}"`,
          });
          return;
        }
      }

      const data = { id: this.id, parent_id: order_new, parent_name: "AccountingDocOrderGoodModel", data_table };
      let response = await this.$axios.post(api, data);
      let status = response.data.status == "ok";
      if (status) {
        this.$root.$emit("show-info", {
          text: "Данные сохранены",
        });
      } else {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка добавления данных",
        });
      }
      this.fitchData();
    },
    async editRows(r) {
      const api = this.url;
      const data_table = r;
      const data = { id: this.id, data_table };
      let response = await this.$axios.post(api, data);
      let status = response.data.status == "ok";
      if (status) {
        this.$root.$emit("show-info", {
          text: "Данные сохранены",
        });
      } else {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка изменения данных",
        });
      }
      this.fitchData();
    },
    async delRow(id) {
      const api = this.url;
      const data_table = this.data.data_table.filter((el) => el.id !== id);
      let parent_name = data_table.length == 0 ? null : this.data.parent_name;
      let parent_id = data_table.length == 0 ? null : this.data.parent_id;
      const data = { id: this.id, parent_id, parent_name, data_table };
      let response = await this.$axios.post(api, data);
      let status = response.data.status == "ok";
      if (status) {
        this.$root.$emit("show-info", {
          text: "Данные удалены",
        });
      } else {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка удаления ",
        });
      }
      this.fitchData();
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      if (this.idShow) this.$emit("close");
      else this.$router.push({ name: "goodsIncome" });
    },
    afterFetchData(r) {
      if (this.id) {
      }
      this.loaded = true;
    },

    async changeStatus(status) {
      let ok = false;
      if (!true)
        if (!this.getAccess("documents.goodsIncome.status." + status.value, null, true)) {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Данное действие не доступно для вашей роли",
          });
          return;
        }
      //      if (status?.role && !status.role.includes(this.$root.profile.role)) {      }

      ok = await this.$refs.confirmDialog.show({
        title: "Изменение статуса",
        message: [`Вы действительно хотите ${status.value !== 2 ? "распровести" : "провести"} докумен приемки?`],
        okButton: "Да",
        cancelButton: "Нет",
      });
      if (!ok) return;
      if (status.value == 2) {
        //проверка перед проводкой
        let q = `SELECT * FROM (
SELECT  ogt_id,SUM(amount) as amount, sum(price) as price  FROM (
SELECT git.id AS 'id', -git.amount AS 'amount', -git.value  AS 'price', git.accounting_doc_order_goods_table_id as ogt_id 
FROM accounting_doc_goods_income_table git
 INNER JOIN accounting_doc_goods_income gi ON gi.id=git.parent_id
WHERE (gi.status=2 OR gi.id=${this.id}) AND git.accounting_doc_order_goods_table_id IN (SELECT accounting_doc_order_goods_table_id FROM accounting_doc_goods_income_table WHERE parent_id=${this.id})
UNION all
select id, amount_real AS 'amount', price_real AS 'price', id AS ogt_id from accounting_doc_order_goods_table WHERE id IN (SELECT accounting_doc_order_goods_table_id FROM accounting_doc_goods_income_table WHERE parent_id=${this.id})
) AS tab
GROUP BY ogt_id
) AS res
WHERE amount<0 OR price<0`;
        let resp = await this.$axios.post("/accounting/records/report-sql", { q });
        if (resp.data.status == "ok") {
          if (resp.data.data.length !== 0) {
            this.$root.$emit("show-info", {
              type: "error",
              text: "Смена статуса невозможна. Принимается больше чем заказано",
            });
            return;
          }
        } else {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Смена статуса невозможна. Ошибка при проверке",
          });
          return;
        }
      }
      //Проверка перед распроводкой
      if (status.value == 1) {
        let q = `
SELECT * FROM (
SELECT store_id, good_id, object_id, SUM(amount) as amount, sum(value) as VALUE 
FROM (
select VALUE,amount,accum.good_id,accum.store_id,accum.object_id
FROM accum_reserve as accum
	INNER JOIN (SELECT distinct good_id,store_id,object_id from accum_reserve WHERE base_name='AccountingDocGoodsIncomeModel' AND base_id=${this.id}) AS doc ON accum.store_id=doc.store_id
		AND accum.good_id=doc.good_id AND ifnull(accum.object_id,-1) = ifnull(doc.object_id,-1)
UNION ALL 		
SELECT -VALUE,-amount,good_id,store_id,object_id from accum_reserve WHERE base_name='AccountingDocGoodsIncomeModel' AND base_id=${this.id}  
) AS tab
GROUP BY store_id, object_id, good_id
) AS res
WHERE amount<0 OR VALUE<0 OR (amount=0 AND VALUE>0)  OR (amount>0 AND VALUE=0)`;
        let resp = await this.$axios.post("/accounting/records/report-sql", { q });
        if (resp.data.status == "ok") {
          if (resp.data.data.length !== 0) {
            this.$root.$emit("show-info", {
              type: "error",
              text: "Смена статуса невозможна. Появляется отрицательный остаток на складе",
            });
            return;
          }
        } else {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Смена статуса невозможна. Ошибка при проверке",
          });
          return;
        }
      }
      this.loading = true;
      let data = { id: this.id, status: status.value };
      let response = await this.$axios.post(this.apiStatus, data);
      this.loading = false;
      status = response.data.status == "ok";
      if (response.data.data) {
        Object.assign(this.data, response.data.data);
        this.$root.$emit("show-info", {
          text: "Статус изменен",
        });
      }
    },
  },
};
</script>
